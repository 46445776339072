/* Base Styles */
* {
  font-family: "Roboto", serif;;
}

.App {
  text-align: left;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.App > .bodyContentHolder {
  min-height:calc(100vh - 150px);
}
.App footer {
  margin-top:auto;
}



/*#root, .App > div {
  width: 100%;
  max-width: 100%;
}*/
hr {
  width:100%;
}

.navHolder {
  z-index: 11111;
  position: relative;
}


.App > div > section {
  display:flex;
  flex-direction:column;
}

.secBody {
  width: 1350px;
  max-width: 90%;
  margin: 0 auto;
  padding-top:25px;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  animation: App-logo-spin infinite 20s linear;
}

@media (prefers-reduced-motion: reduce) {
  .App-logo {
    animation: none;
  }
}

/* Header */
header {
  display: flex;
  align-items: center;
  width: 1350px;
  max-width: 90%;
  margin: 0 auto;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Navigation */
nav {
  margin: 1rem 0;
  flex-grow: 1;
  text-align: right;
}

nav a {
  margin-right: 1rem;
  text-decoration: none;
  color: #fa2c6d;
  font-weight: 500;
}

nav a:hover {
  color: #11ceeb;
}

nav .dropdown-content {
  text-align: left;
  padding-top:15px;
  padding-bottom:15px;
}

/* Card Catalog */
.rightCardPanel {
  min-width: 50%;
  flex-grow:1;
}

.cardListCSS ul, .trading-hub .cards-grid, .wanted-cards-grid {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.cardListCSS ul > li,  .trading-hub .cards-grid .card-item, .wanted-cards-grid .wanted-card-item {
  /*width: 20%;
  max-width:200px;
  padding: 0% 0% 1% 0%;
  margin: 0 2% 3% 0%;*/
  text-align: center;
  border-radius: 0px;
  border: 1px solid rgba(0, 0, 0, 1);
  -webkit-box-shadow: 1px 3px 0px 0px rgba(255, 42, 109, 1);
  -moz-box-shadow: 1px 3px 0px 0px rgba(255, 42, 109, 1);
  box-shadow: 1px 3px 0px 0px rgba(255, 42, 109, 1);
  transition:300ms ease-in-out all;
  position:relative;
  border-radius: 10px;
  overflow:hidden;
  margin: 7px;
  margin-bottom: 14px;
  width: calc(16.66% - 14px - 2px);
}

@media (max-width:980px) {
  .cardListCSS ul > li, .wanted-cards-grid .wanted-card-item {
    width: calc(25% - 14px - 2px);
  }
}
@media (max-width:767px) {
  .cardListCSS ul > li, .wanted-cards-grid .wanted-card-item {
    width: calc(33.33% - 14px - 2px);
  } 
}
@media (max-width:426px) {
  .cardListCSS ul > li, .wanted-cards-grid .wanted-card-item {
    width: calc(50% - 14px - 2px);
  } 
}
@media (max-width:325px) {
  .cardListCSS ul > li, .wanted-cards-grid .wanted-card-item {
    width: 100%;
  } 
}

/*.cardListCSS ul > li:nth-child(5n + 1) {
  margin-left:0px;
}

.cardListCSS ul > li:nth-child(4n) {
  margin-right:0px;
}*/

.cardListCSS ul > li:hover {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(255, 42, 109, 1);
    -moz-box-shadow: 0px 0px 0px 0px rgba(255, 42, 109, 1);
    box-shadow: 0px 0px 0px 0px rgba(255, 42, 109, 1);
    transition:300ms ease-in-out all;
    /*transform: translateY(-10px);*/
}

.cardListCSS ul > li img, .trading-hub .cards-grid img, .wanted-cards-grid .wanted-card-item img {
  max-width: 100%;
  max-height: 291px;
}

.cardListCSS ul > li h3 {
  font-weight: 400;
}


/* Logo */
header {
  padding-top:5px;
  padding-bottom:5px;
}
header .menuLogo {
  max-width:66%;
}
header .menuLogo img {
  max-width:100%;
}
.menuLogo {
  width: 275px;
}

/* Animations */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Side Filters */
.sideFilterPar, .sideFilter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.sideFilterPar, .sideFilter > div {
  margin-left:10px;
  margin-right:10px;
}
.sideFilter > div {
  margin-left:3px;
  margin-right:3px;
}


.sideFilterPar, .sideFilter ul.dropdown-options li > label {
  width: 100%;
  display: inline-block;
}
.deck-builder .cardBuilderPar .deckBuilderContainer .sideFilterPar {
  margin-left:0px;
  margin-right: 0px;;
}


.sideFilterPar  h3 {
  color:#ff2a6d;
  margin-bottom:10px;
  margin-top:25px;
}

.cost-filter > div {
  width:75px;
}
.cost-filter h3 {
  margin-top:0px;
}

.GroupFilter #groupFilter {
  max-width:100%;
}

 .color-options, .type-options  {
  display: flex;
  flex-direction: column;
}
.sideFilter input[type='checkbox'] {
  display:none;
}
.sideFilter li {
  /*border: 2px solid black;*/
  padding: 4px 8px;
  text-transform: uppercase;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 5px;
}
.sideFilter label:has(input[type="checkbox"]:checked) {
  background-color:#15cfed;
  border-color:#15cfed;
}

.multi-select-dropdown .dropdown-options {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  list-style: none;
  padding: 10px;
  margin: 0;
  overflow-y: auto;
  z-index: 1000;
  display: flex;
    flex-direction: column;
}

/* Card Info Popup */
.cardInfoPop {
  display: flex;
  flex-direction: column;
  max-width:450px;
  padding:0px !important;
}
.cardInfoPop .imgHolder {
  text-align: center;
}
.cardInfoPop .imgHolder, .cardInfoPop .imgHolder img {
  width:400px;
  /*margin-right:20px;*/
  max-width:100%;
}
.cardInfoPop p {
  margin: 5px 0px;
}
.cardDetailPopup .cardInfoPop .imgHolder img {
  max-height:500px;
  width:auto;
}

.cardListCard h3 {
  margin-top:0px;
}

/* Basic Button */
button, a.button {
  background-color: #fff !important;
  color:#000 !important;
  font-size: 18px !important;
  /*margin-top: 10px !important;*/
  margin-bottom:10px !important;
  -webkit-box-shadow: 1px 3px 0px 0px rgba(255, 42, 109, 1) !important;
  -moz-box-shadow: 1px 3px 0px 0px rgba(255, 42, 109, 1) !important;
  box-shadow: 1px 3px 0px 0px rgba(255, 42, 109, 1) !important;
  transition:300ms ease-in-out all !important;
  border:2px solid #000 !important;
  font-weight:500 !important;
  border-radius: 0px !important;
  padding: .25em .5em;
  text-decoration: none !important;
}
button:hover, a.button:hover {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(255, 42, 109, 1) !important;
    -moz-box-shadow: 0px 0px 0px 0px rgba(255, 42, 109, 1) !important;
    box-shadow: 0px 0px 0px 0px rgba(255, 42, 109, 1) !important;
    transition:300ms ease-in-out all !important;
}

button.detailsBttn {
  font-size:16px !important; 
}
button.viewBttn {
  font-size:16px !important;
}
.cardListCard .magnifier {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  opacity: .6;
}

.card-text-on, .card-text-whe, .card-text-don, .card-text-onc, .card-text-you, .card-text-blo, .card-text-cou, .card-text-tri, .card-text-opp {
  color:#fff;
  border-radius: 5px;
  padding:2px 5px; 
}
.card-text-on, .card-text-whe, .card-text-you, .card-text-opp {
  background-color:#0570a7;
}
.card-text-don {
  background-color:#000;
}
.card-text-onc, .card-text-cou {
  background-color:#f43f5e;
}
.card-text-blo {
  background-color:#f97316;
}
.card-text-tri {
  background-color:yellow;
  color:#000;
}

.dropdown-item.ranged, .dropdown-item.ranged-strike, .dropdown-item.slash, .dropdown-item.slash-special, .dropdown-item.slash-strike, .dropdown-item.special, .dropdown-item.special-strike, .dropdown-item.strike, .dropdown-item.wisdom {
  display: flex;
  align-items: center;
}
.dropdown-item.ranged:before, .dropdown-item.ranged-strike:before, .dropdown-item.slash:before, .dropdown-item.slash-special:before, .dropdown-item.slash-strike:before, .dropdown-item.special:before, .dropdown-item.special-strike:before, .dropdown-item.strike:before, .dropdown-item.wisdom:before {
  content:'';
  width:25px;
  height:20px;
  background-size: contain;
  background-repeat:no-repeat;
}

.dropdown-item.ranged:before {background-image: url("https://storage.googleapis.com/card-tracker-images/UI-Images/ranged.webp");}
.dropdown-item.ranged-strike:before {background-image: url("https://storage.googleapis.com/card-tracker-images/UI-Images/ranged-strike.webp");}
.dropdown-item.slash:before {background-image: url("https://storage.googleapis.com/card-tracker-images/UI-Images/slash.webp");}
.dropdown-item.slash-special:before {background-image: url("https://storage.googleapis.com/card-tracker-images/UI-Images/slash_special.webp");}
.dropdown-item.special:before {background-image: url("https://storage.googleapis.com/card-tracker-images/UI-Images/special.webp");}
.dropdown-item.special-strike:before {background-image: url("https://storage.googleapis.com/card-tracker-images/UI-Images/strike_special.webp");}
.dropdown-item.strike:before {background-image: url("https://storage.googleapis.com/card-tracker-images/UI-Images/strike.webp");}
.dropdown-item.wisdom:before {background-image: url("https://storage.googleapis.com/card-tracker-images/UI-Images/wisdom.webp");}
.dropdown-item.slash-strike:before {background-image: url("https://storage.googleapis.com/card-tracker-images/UI-Images/slash_strike.webp");}


.set-filter #groupFilter {
  max-width:100px;
}

br {
  margin-bottom:10px;
}

input[type=text], select {
  border-radius:0px;
  background-color: rgba(0, 0, 0, 0);
  border-width:2px;
  border-color:#000;
  font-size: 18px;
  /*margin-top: 10px;*/
  margin-bottom: 10px;
  box-shadow: 1px 3px 0px 0px rgba(255, 42, 109, 1);
  transition: 300ms ease-in-out all;
  outline:none;
}
input[type=text]:focus {
  box-shadow: 0px 0px 0px 0px rgba(255, 42, 109, 1);
}


.navArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  z-index: 10;
}

.leftArrow {
  left: 0px;
  transform: translate(-100%, -50%);
  background: white;
}

.rightArrow {
  right: 0px;
  transform: translate(100%, -50%);
  background: white;
}

.cardDetailPopup {
  position: relative;
  padding: 1rem;
  padding-top:0px;
  background: white;
  border-radius: 8px;
}

.cardDetailTitle {
  text-align:center;
  font-weight: bold;
  font-size: 22px;
}

.cardInfo img {
  max-width: 100%;
  border-radius: 8px;
}

.modal-close {
  z-index:1111;
}


.selected-cards .card-container-card > div > h2  {
  display:none;
}

.card-container-leader, .card-container-leader img {
  max-width:250px;
}

.selected-cards .card-container-card > div > ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.selected-cards .card-container-card > div > ul > li {
  width: 21.33%;
  padding: 2% 1%;
  margin: 0 2% 3% 0%;
  text-align: center;
  border-radius: 0px;
  border: 1px solid rgba(0, 0, 0, 1);
  box-shadow: 1px 3px 0px 0px rgba(255, 42, 109, 1);
  transition: 300ms ease-in-out all;
  position: relative;
}
.selected-cards .card-container-card > div > ul > li img {
  max-width:100%;
}

.cardBuilderPar .rightCardPanel.cardListCSS ul > li, .deckBuilder li.cardListCard {
  max-width:90px;
  margin:5px !important;
}
.cardBuilderPar .rightCardPanel.cardListCSS ul > li {
  max-width:150px;
}
.deckBuilder li.cardListCard {
  position: relative;
    display: block;
}
.deckBuilder li.cardListCard img {
  max-width:100%;
}
.cardBuilderPar .rightCardPanel.cardListCSS ul > li > h3, .deckBuilder li.cardListCard h3 {
  display:none;
}

.cardListCard .image-container {
  margin-bottom:10px;
}
.cardListCard .card-buttons {
  text-align: center;
}
.cardListCard .card-buttons > button {
  margin:5px;
}
.cardBuilderPar .cardListCard {
  padding:0px 0px 0px 0px!important; 
}

.rightCardPanel.cardListCSS, .deckBuilder {
  max-height:50vh;
  overflow:scroll;
}

.owned-filter {
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.owned-filter label {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.OwnedField {
  margin-bottom:10px;
}

.cardTitle {
  margin-top:0px;
  margin-bottom:0px;
  font-weight:600;
  font-size:16px;
  padding: 0px 5px;
  /*background-color: #000000;
  color: #fff;*/
  margin-bottom: 10px;
}

.owned-filter input[type="checkbox"] {
  appearance: none;
  width: 50px;
  height: 24px;
  background: #ccc;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}

.owned-filter input[type="checkbox"]:checked {
  background: #4CAF50;
}

.owned-filter input[type="checkbox"]::before {
  content: '';
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: 0.3s;
}

.owned-filter input[type="checkbox"]:checked::before {
  left: 28px;
}

.deckCards {
  display: flex;
      flex-direction: row;
      flex-wrap: wrap;
}

.deckCards .card-container {
  position:relative;
  margin:15px;
}

.deckCards .card-container .card-quantity,  .deck-quantity{
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 11;
  background-color: black;
  color: white;
  height: 25px;
  width: 25px;
  border-radius: 1000px;
  display: flex;
  justify-content: center;
}

.deck-view {
  padding: 20px;
  padding-top:0px;
}
.deck-view .card-quantity {
  margin-top:0px !important;
}
.deck-view .deck-cards-container .leaderSection {
  max-width:200px;
  text-align: center;
}
.deck-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-icon {
  cursor: pointer;
}

.deck-preview {
  cursor: pointer;
  transition: transform 0.2s;
}

.deck-preview-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}


.loginHolder .logoHolder {
  text-align:center;
}
.loginContainter {
  background-color:#fffffff7;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.18);
  -moz-box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.18);
  box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.18);
  padding: 25px 45px;
}
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.full-width {
  width: 100%;
}

.divider-text {
  text-align: center;
  font-size: 0.9rem;
  margin: 1rem 0;
}
.switch-auth {
  text-align: center;
  margin-top: 1rem;
}

.auth-link {
  color: #007bff;
  cursor: pointer;
  margin-left: 5px;
}

.auth-link:hover {
  text-decoration: underline;
}

.deckBuilderContainer {
  z-index: 1111;
  background-color: #fff;
}



/* Deck Library */
.deck-library .decks-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.deck-library .decks-grid .deck-preview {
  max-width:250px;
  position: relative;
  border: 5px solid black;
  border-radius: 20px;
  overflow: hidden;
  margin:10px;
}
.deck-library .decks-grid .deck-preview h2 {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: #000000d1;
  color: white;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size:18px;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 15px;
}

.hamburger span {
  width: 25px;
  height: 3px;
  background: #ff2a6d;
  margin: 2px 0;
  transition: 0.3s;
}

@media screen and (max-width: 768px) {
  .hamburger {
      display: flex;
  }

  nav {
      display: none;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      background: #ffffff;
      flex-direction: column;
      padding: 20px;
  }

  nav.nav-active {
      display: flex;
      max-width: 80%;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 111;
      -webkit-box-shadow: 0px 5px 7px 0px rgba(0,0,0,0.34);
      -moz-box-shadow: 0px 5px 7px 0px rgba(0,0,0,0.34);
      box-shadow: 0px 5px 7px 0px rgba(0,0,0,0.34);
  }
  nav .dropdown-content {
    padding-top:0px;
    padding-bottom:0px;
    background-color: unset;
  }
  .dropdown-content a {
    padding: 5px 20px !important;
  }
  nav a, nav .dropDownTitle {
      margin: 10px 0;
      display: inline-block;
  }
  header {
    justify-content: space-between;
  }
  header nav {
    text-align:left;
  }
}


/* Deck View */
.deck-view .deck-leader {
  /*max-width:200px;*/
}
.deck-view .deck-leader h2 {
  margin-bottom: 0px;
  margin-top: 0px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 25px 25px 0px 0px;
}
.deck-view .deck-leader .card-container img {
  max-width:100%;
}

.tcgPlayerBttn {
  width:100%;
  text-align: center;
  margin-top:0px;
}
.tcgPlayerBttn a {
  margin-top:0px !important;
}

/* Deck Builder */
.deckBuilderHead {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.deckBuilderHead h2 {
  margin-top:0px;
  margin-bottom:0px;
  flex-grow:1;
}
.deckSection {
  z-index:1;
  position: relative;
}

.deckSection .leaderSection, .deckSection .deckCards .card-container, .deck-view .deckCards .card-container {
  max-width:200px;
}
.deck-view .deckCards .card-container {
  margin-left:0px !important;
  margin-right:0px !important;
  text-align: center;
}
.deckSection .leaderSection img, .deckSection .deckCards .card-container img , .deck-view .deckCards .card-container img{
  width:100%;
}
.deckSection .leaderSection ul {
  list-style-type: none;
  padding-left: 0px;
}
.deckSection .leaderSection > h3, .deck-view .leaderSection > h2 {
  margin-bottom:0px;
  margin-top:0px;
}
.deckSection .leaderSection ul {
  margin-top:0px;
}
.deckSection .leaderSection ul h3 {
  margin-bottom: 0px;
}
.deckSection h3, .deck-view .leaderSection > h2 {
  text-align:center;
}
.deck-builder .deck-cards-container, .deck-view .deck-cards-container {
  display: grid; /* Use grid layout */
  grid-template-columns: auto 1fr; /* Two columns: one for leaderSection, one for deckCards */
  gap: 10px; /* Optional: spacing between grid items */
}

.deck-builder .leaderSection {
  grid-row: 1; /* Keep the leaderSection in the first row */
}

.deck-builder .deckCards, .deck-view .deckCards,  .analytics-content .missingCards .missing-cards-grid {
  display: grid; /* Use grid for the deckCards section */
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Flexible columns for cards */
  gap: 10px; /* Spacing between cards */
  grid-column: 2; /* Ensure deckCards stays in the second column */
}
.deck-builder .deckCards .card-container {
  margin:0px;
  text-align:center;
}
.deck-builder .deckCards .card-container .card-controls button {
  margin:5px;
}
.deck-builder .leaderSection .cardListCard .cardTitle {
  text-align: center;
  margin-bottom:0px;
}
.analytics-content .missingCards {
  width:100%;
}
.analytics-content .missingCards .missing-cards-grid img {
  width:100%;
}
.analytics-content .missingCards .missing-cards-grid .card-container {
  margin:0px;
}

.analytics-content .missingCards .complete-message {
  grid-column:1 / 9;
}
.missingCards .missing-cards-grid .card-container {
  margin-bottom:20px;
}

.cards-needed {
  margin-bottom:7px;
}

@media (max-width:980px) {
  .deck-builder .deck-cards-container {
    display:flex;
    flex-direction: column;
  }
}

@media (max-width:767px) {
  .deck-view .deck-cards-container {
    display: block;
  }
  .deck-view .deck-cards-container .leaderSection {
    text-align: center;
  }
}

.MuiDialog-container p {
  margin-bottom:0px !important;
  margin-top:0px !important;
}

/* Share Module */
.MuiDialog-root .MuiPaper-elevation {
  /*width:900px;*/
  width:fit-content;
  max-width:80%;
}
.MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
  /*padding-bottom:0px;*/
}
.css-zw3mfo-MuiModal-root-MuiDialog-root
.viewBttnHldr {
  text-align: center;
}
.deckBuilderViewDeckbttn {
  width: 90%;
  color: #fff;
  background-color: black;
  padding: 10px;
}
.MuiDialog-root {
  z-index:11111 !important;
}

.builderCardPickerHolder {
  display: flex  ;
  flex: 1 1 0%;
  min-height: 0px;
  flex-direction: column;
  max-width:1080px;
  margin-left:auto;
  margin-right:auto;
  min-height: unset !important;
  max-height:80vh;
  background-color: #fff;
}
.builderCardPickerHolder .rightCardPanel.cardListCSS {
  max-height:unset;
}

.image-placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 300px; /* Adjust based on your image size */
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

/* Card Building Sidebar */
.deck-analytics .analytics-content .title{
  font-weight: 600;
}
.deck-analytics .analytics-content .distribution-section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.deck-analytics .analytics-content .distribution-section .distribution-row {
  padding: 3px 5px;
  margin: 5px;
  border: 2px solid black;
}

.analytics-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.analytics-content .AnalyticsTitle {
  width:100%;
}
.analytics-content > div {
  margin-left:15px;
  margin-right:15px;
}

.fullWidth {
  width:100%;
}
.deck-analytics .analytics-content h3 {
  text-align: left;;
}

/* Lazy Image Load 
.card-image {
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.card-image.loaded {
  opacity: 1;
}

.image-placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 300px;
  border-radius: 8px;
} */



.importDeckModule {
  position: absolute;
  top: 0;
  z-index: 111;
  background-color: #fff;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  padding: 25px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.36);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.36);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.36);
}

/* Add these styles to App.css */
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown > span {
  margin-right:16px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1111;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  padding: 12px 16px;
  display: block;
}

@media (max-width:768px) {
  .dropdown-content {
    display:block;
    position: relative;
    box-shadow: unset;
  }
}


/* Homepage */
.home-container {
  padding: 20px;
}

.collection-value {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.deck-statistics {
  background: white;
  padding: 20px;
  border-radius: 8px;
}

.filters {
  display: flex;
  gap: 20px;
  margin: 20px 0;
  flex-wrap:wrap;
}

.color-filters {
  display: flex;
  gap: 10px;
}

.decks-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.deck-card {
  background: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
}

.home-container .decks-grid {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.home-container .deck-preview {
  width:calc(16.66% - 20px);
  margin-bottom: 20px;
  padding:10px;
}
.home-container .deck-preview h2 {
  margin-top:5px;
  margin-bottom:0px;
  text-align: center;
  font-size:18px;
  font-weight: 500;
}

@media (max-width:980px) {
  .home-container .deck-preview {
    width:calc(33% - 20px);
  }
}
@media (max-width:767px) {
  .home-container .deck-preview {
    width:calc(50% - 20px);
  } 
}
@media (max-width:400px) {
  .home-container .deck-preview {
    width:calc(100% - 20px);
  }  
}

.home-container .dropdown-options {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  list-style: none;
  padding: 10px;
  margin: 0;
  overflow-y: auto;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}
.home-container .dropdown-options .dropdown-item {
  padding: 4px 8px;
  text-transform: uppercase;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 5px;
}
.home-container .dropdown-options .dropdown-item > label {
  width: 100%;
  display: inline-block;
}
.home-container .dropdown-options .dropdown-item > label input[type='checkbox'] {
  display: none;
}
.home-container .dropdown-options label:has(input[type="checkbox"]:checked) {
  background-color: #15cfed;
  border-color: #15cfed;
}

.home-container .collection-value h2 {
  margin-top:0px;
  margin-bottom: 0px;
  margin-right:10px;
}
.home-container .collection-value h2, .home-container .collection-value .total-value {
  display:inline-block;
}

.home-container .showDecksSelectLeader {
  max-width:200px;
}

.stats-container {
  display: flex;
  gap: 2rem;
  margin: 20px 0;
}
.pie-chart {
  flex: 0 0 50%;
  height: 300px;
}
.leaders-list {
  flex: 0 0 45%;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
}
.leader-item {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #eee;
}

/* Loading Spinner */
.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Progress Bar */
.set-progress-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.set-item {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  background-color: #f5f5f5;
  cursor: pointer;
  transition: transform 0.2s;
}

.set-item:hover {
  transform: translateY(-2px);
}

.progress-bar-container {
  width: 100%;
  height: 20px;
  background-color: #ddd;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #4CAF50;
  transition: width 0.3s ease;
}

.progress-text {
  margin-top: 5px;
  text-align: right;
  font-size: 14px;
  color: #666;
}

/* Progress Page */
.set-progress-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width:100%;
}
.set-progress-container .set-item {
  width:calc( 45% - 30px);
  margin:0px 15px 25px 15px;
}
.set-progress-container h3 {
  margin-top:0px;
}
@media (max-width:767px) {
  .set-progress-container .set-item {
    width:100%;
    margin-left:0px;
    margin-right:0px;
  }
}


/* Footer */
.app-footer {
  background-color: #fff;
  color: #000;
  padding: 1rem 0;
  text-align: center;
  
  bottom: 0;
  width: 100%;
  z-index: 100;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
}
.footer-content p {
  margin-top:5px;
  margin-bottom:5px;
}
.footer-links a {
  color: white;
  text-decoration: none;
  margin-left: 1rem;

}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-content .termsPrivacy {
  display:inline-block;

}
.footer-content .termsPrivacy a {
  display:inline-block;
  margin-left:10px;
  margin-right:10px;
}

/* Add padding to prevent content from being hidden behind footer */
.secBody {
  padding-bottom: 60px;
  padding-top:10px;
}


.MuiSvgIcon-root {
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.4);
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.4);
  border-radius: 100px;
  padding: 5px;
  color: white;
  background: black;
}

.share-icon {
  cursor: pointer;
  font-size: 1.5rem;
  color: #4a4a4a;
  padding: 8px;
}

.share-icon:hover {
  color: #2196F3;
}
.cardDetailPopup .share-icon {
  position: absolute;
  top: -15px;
  left: -15px;
}
.cardDetailPopup .cardInfo .view-full-page-button {
  width:100%;
  text-align: center;
}

/* Card Detail Page */
.card-detail-page .cardInfoPop {
  width:100%;
  max-width:100%;
  display: flex  ;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.card-detail-page .cardInfoPop .cardDetailTitle {
  text-align: left;
  width:100%;
}
.card-detail-page .imgHolder {
  margin-right: 25px;
  max-width: calc(30% - 25px);
}
.card-detail-page .cardInfo {
  width: calc(70% - 30px);
  max-width: 500px;
}

.card-detail-page .related-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.card-detail-page .related-content .decks-section {
  width: calc(50% - 10px);
  margin-right: 20px;
}
.card-detail-page .related-content .related-cards-section {
  width: calc(50% - 10px);
}
.card-detail-page .related-content .related-cards-section .cards-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.card-detail-page .related-content .related-cards-section .card-preview {
  max-width:47%;
  margin-bottom:20px;
  cursor: pointer;
}
.card-detail-page .related-content .related-cards-section .card-preview img {
  width:auto;
  max-width:100%;
}
.card-detail-page .cardInfo p {
  margin-bottom:15px;
}
@media (max-width:767px) {
  .card-detail-page .cardInfoPop, .card-detail-page .related-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .card-detail-page .imgHolder {
    margin-right:0px;
    max-width:400px;
  }
  .card-detail-page .cardInfo {
    max-width:100%;
    text-align: left;
  }
  .card-detail-page .related-content .decks-section, .card-detail-page .related-content .related-cards-section {
    width:100%;
    margin-right:0px;
  }
}

/* Login */
.loginBody {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.trading-hub .trading-hub {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.trading-hub .search-section {
  margin: 20px 0;
}

.trading-hub .search-input {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.trading-hub .users-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.trading-hub .user-card {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s;
}

.trading-hub .user-card:hover {
  transform: translateY(-2px);
}

.trading-hub .user-stats {
  margin: 10px 0;
  color: #666;
}

.trading-hub .user-actions {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.trading-hub .user-actions button {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.trading-hub .view-collection-btn {
  background-color: #4CAF50;
  color: white;
}

.trading-hub .view-wishlist-btn {
  background-color: #2196F3;
  color: white;
}

.trading-hub .user-actions button:hover {
  opacity: 0.9;
}

.trade-notifications {
  display:inline-block;
}

/* Trade Notifications Styles */
.trade-notifications {
  position: relative;
  display: inline-block;
}

.notification-badge {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  background-color: #ff4444;
  color: white;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.notification-badge:hover {
  background-color: #ff6666;
}

.notification-badge button {
  background: transparent;
  border: 1px solid white;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.notification-badge button:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* Trade Offer Modal */
.trading-hub .modal-overlay > .modal-content {
  width:1150px !important;
  height:90%;
}
.trade-offer-modal {
  /*max-width: 90%;
  max-height: 80vh;*/
  height:100%;
  overflow-y: auto;
}

.trade-offer-modal .trade-sections-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.trade-offer-modal .left-section, .trade-offer-modal .right-section {
  /*flex: 1;
  min-width: 45%;*/
}
.trade-offer-modal .right-section {
  flex-grow:1;
}
.trade-offer-modal .left-section {
  max-width:250px;
}

.trade-sections-container ul {
  padding-left:0px;
  list-style: none;
}
.trade-sections-container ul li img {
  max-width:100%;
}
@media (max-width: 768px) {
  .trade-sections-container {
    flex-direction: column;
  }
}

.trade-offer-modal .card-selection {
  margin-top: 15px;
  max-height: 400px;
  overflow-y: auto;
}

.trade-offer-modal .modal-actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.trade-offer-modal > h2 {
  margin-bottom:0px;
}

.cardListCSS.card-selection ul > li {
  width: calc(23.66% - 14px - 2px);
}




.trade-offer-modal .selected-cards-section {
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 15px;
}

.trade-offer-modal .selected-cards-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 300px;
  overflow-y: auto;
}

.trade-offer-modal .selected-card-item {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 8px;
}

.trade-offer-modal .selected-card-image {
  width: 60px;
  height: auto;
  margin-right: 10px;
}

.trade-offer-modal .selected-card-details {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trade-offer-modal .remove-card-btn {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.trade-offer-modal .remove-card-btn:hover {
  background-color: #ff3333;
}


/* Trade Offers */
.incoming-trades .trade-details li img, .outgoing-trades .trade-details li img {
  max-width:100%;
}
.incoming-trades .trade-details .cardListCard, .outgoing-trades .trade-details .cardListCard {
  max-width:250px;
}
.incoming-trades .trade-details ul, .outgoing-trades .trade-details ul {
  list-style: none;
  padding-left: 0;
}
.trade-management .trade-details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap:wrap;
}
.trade-management .trade-card {
  margin-bottom:55px;
}
.trade-management .tradeDetailsOffering {
  padding-right:55px;
  position: relative;
}
.trade-management .tradeDetailsOffering:after {
  position:absolute;
  content:'';
  right:25px;
  height:100%;
  top:0px;
  width:2px;
  background-color:#000;
}
.outgoing-trades .trade-details h3, .outgoing-trades .trade-details h4, .outgoing-trades .trade-details h5 {
  margin-bottom:5px;
  margin-top:0px;
}
.trade-management .tradeDetailsRequest ul {
  display: flex  ;
  flex-direction: row;
  flex-wrap: wrap;
}
.trade-management .tradeDetailsRequest ul li {
  margin:5px;
}
.trade-management .tradeDetailsRequest ul img {
  max-width:100%;
}
.trade-management .tradeDetailsRequest ul li.cardListCard {
  max-width:150px;
}